export default {
  data: () => ({
    isSubmitting: false
  }),
  computed: {
    isFormDirty() {
      return (
        this.$refs.observer &&
        this.$refs.observer.flags.dirty &&
        !this.isSubmitting
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty) {
      this.$bvModal
        .msgBoxConfirm(
          "You have unsaved changes. Do you want to leave current page?",
          {
            title: "Before you that...",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Leave",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          if (value) {
            this.isSubmitting = false;
            next();
          }
        })
        .catch(err => {
          console.error(err);
        });

      // const answer = window.confirm(
      //   "You have unsaved changes. Do you really want to leave?"
      // );
      // if (answer) {
      //   this.isSubmitting = false;
      //   next();
      // }
    } else {
      next();
    }
  },
  methods: {
    getValidationState: ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    }
  }
};
